import React from "react"
import { Link } from 'gatsby';
import astroImage from '../images/404-astronaut.svg'
import Layout from "../components/layout"
import styled from 'styled-components';
import Container from '../components/Container';
import OutlinedContainer from '../components/OutlinedContainer'
import PremiumDomainsSidebar from '../components/PremiumDomainsSidebar'
import SEO from '../components/seo';
import Social from '../components/Social';

const StyledLink = styled(props => <Link {...props} />)`
    color: lightgrey;
    text-transform: uppercase;
    border: 1px solid lightgrey;
    border-radius: 16px;
    padding: 5px 10px;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 15px;
    display: inline-block;
    transition: .25s;
    background-color: lightslategrey;
    &:hover {
        background-color: slategrey;
        color: ghostwhite;
    }
`

const NotFoundPage = ({ sidebarDomains }) => {
  return (
    <Layout>
      <SEO
        title="404 - Page Not Found"
      />
      <Container>
        <br />

        <OutlinedContainer style={{
          display: 'block',
          textAlign: 'center',
          minHeight: '50vh'
        }}>
          <h1>404 - Page Not Found</h1>
          <p>Looks like we forgot to connect something. The page you're looking for is missing.</p>
          <p>
            [ <Link to='/'>Home</Link> | <Link to='/premium-domains'>Premium Domains</Link> ]
          </p>
          <img
            src={astroImage}
            alt="astronaut"
            width={500}
          />
        </OutlinedContainer>

      </Container>
    </Layout >
  )
};


export default NotFoundPage;